.main-footer {
    font-size: 14px;
    position: relative;
    padding: 10px 50px;
    background-color: rgba(234, 241, 245, 0.4);
    background-position: center bottom;
    background-repeat: no-repeat;
}

footer a {
    color: #222222;
}

.main-footer p {
    margin: 0 0 10px;
}

@media screen and (max-width: 499.99px) {
    .main-footer {

        padding: 15px 30px;

    }
}
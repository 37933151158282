
 .faq-accordion .card {
    display: flex;
    flex-wrap: wrap;
  }

.faqAccordion .card .card-header {
    white-space: normal;
    text-align: left;
    /* line-height: 12px; */
    letter-spacing: 1px;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 20px 30px;
    font-weight: 600;
    color: #007bff;
    cursor: pointer;
}

.faqAccordion .card .card-body {
    padding: 30px;
    padding-left: 45px;
}

.faqAccordion .card .card-header:hover {
    color: #0056b3;
}
  
  .faq-question {
    width: 100%;
    flex-basis: 100%;
    order: 1;
  }
  
  .faq-answer {
    order: 2;
  }
  
 /* Style for when the body is closed */
  .faq-question::before {
    color: #3c4d6b;
    content:"+";
    border-radius: 50%;
    margin-right: 5px;
  }

  /* Style for header when body is fully open */
  .faq-answer.show + .faq-question::before {
    color: #F5911E;
    content:"-" ;
  }

#contactUsForm {
  background-image: linear-gradient(to right, #0054a6 , #5699b6);
  padding: 48px 15px 60px;
  margin-top: 35px;
  color: #fff;
}

#contactUsForm input[type="text"], input[type="email"], input[type="tel"] {
  border-color: #F5911E;
  border-style: solid;
  border-width: 0 0 1px;
  color: #fff;
  font-size: 16px;
  padding: 30px 10px;
  background-color: rgba(255, 0, 0, 0)!important;
  width: 100%;
}

#contactUsForm ::placeholder {
  color: #fff;
}
.borrowerLeadGeneration .divflex {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 1rem;
}
#modalView .modal-title{
  font-size: 22px !important;
  text-align: center !important;
  color: #00aae4 !important;
  padding: 5px 40px;
}
#modalView .modal-header .close {
  padding: 1rem 1rem;
  margin: -6rem -1rem -1rem auto;
}
#modalView .modal-header {
  border-bottom: none !important;
}
#modalView .modal-body .modal-header {
  display: none;
}
#modalView .form-select{
  border-left: 2px solid #1d90cc;
  border-right: 2px solid #1d90cc;
}
#modalView .form-control{
  border-left: 2px solid #1d90cc;
  border-right: 2px solid #1d90cc;
}

 
#contactUsForm textarea {
  border-color: #F5911E;
  border-style: solid;
  border-width: 0 0 1px;
  height: 123px;
  margin-top: 12px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: rgba(255, 0, 0, 0);
}

#contactUsForm Button[type="submit"] {
  background-color: rgba(245,145,30,1);
  color: #fff;
  font-size: 16px;
  padding: 7px 15px;
  box-shadow: 0 0 10px #345063;
  border: none;
  border-radius: 20px;
  white-space: normal !important;
}
#contactUsForm .btn-success{
  background-color: #198754 !important;
  border: 1px solid #198754 !important;
  color: #fff;
}
#contactUsForm .btn-success:hover{
  background-color: #149257 !important;
  border: 1px solid #198754  !important;
  color: #fff !important;
}
#contactUsForm Button[type="submit"]:hover {
  background-color: #fff;
  border: 1px solid #F5911E;
  color: #F5911E ;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

#contactUsForm .form-control {
  border-radius: 0rem;
}

.form-group {
  margin-bottom: 0rem;
}

#contactUsForm .form-control:focus {
  color: rgba(255, 255, 255, 0.85);
}

.invalid-feedback {
  color: #dc3545 !important;
}

.btn.disabled:hover,
.btn:disabled:hover {
  cursor: not-allowed;
  border: none !important;
  color: #fff !important;
  background-color: #f5911e !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")!important; */
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

#contactUsForm .form-check-label {
  color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
}
#contactUsForm .consentLink {
  color: #fff !important;
}
#contactUsForm .consentLink:hover {
  color: #f5911e !important;
  text-decoration: underline;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545!important;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")!important;
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

#contactUsForm .form-check-input:checked {
  background-color: #f5911e !important;
  border-color: #f5911e !important;
}

#contactUsForm .form-link:nth-of-type(2) {
  margin-right: 0.5em;
}

#contactUsForm .form-link:hover {
  text-decoration: underline !important;
}

#contactUsForm h6 {
  text-align: left;
  color: #fff;
  font-size: 12px;
}

#contactUsForm .blg-col-9 {
  text-align: left;
  font-size: 12px;
  color:#fff !important;
}

#contactUsForm .consent-font {
  font-size: 12px;
}
.btn-submit{
  margin: 0 !important;
}
#contactUsForm .consent-font a {
  color: #f5911e;
}

.blg-col-9 .form-check:nth-of-type(2) .form-check-label {
  padding-right: 0 !important;
}

.blg-container.container-fluid {
  padding-top: 0 !important;
}

.blg-col-0 {
  display: none !important;
}

#contactUsForm .blg-col-1,
#contactUsForm .blg-col-2,
#contactUsForm .blg-col-3,
#contactUsForm .blg-col-4{
  width: 100% !important;
}
#modalView .blg-col-1{
  width: 50% !important;
}
#modalView .blg-col-2{
  width: 50% !important;
 
}
#modalView .blg-col-3{
  width: 50% !important;
}
#modalView .blg-col-4{
  width: 50% !important;
}
.blg-col-5 {
  display: none !important;
}
/* #contactLeadform .blg-col-2{
  margin-top: 10px !important;
} */
.blg-col-ty {
  background-color: #fff;
  border-radius: 1rem;
  margin-top: 60px !important;
}

.blg-col-ty h4 {
  font-size: 22px;
}

#contactUsForm select {
  border-color: #f5911e;
  border-style: solid;
  border-width: 0 0 1px;
  color: #fff;
  font-size: 16px;
  padding: 18px 10px;
  background-color: rgba(255, 0, 0, 0) !important;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

#contactUsForm option {
  color: #164686;
}

.btn-blg.btn.btn-outline-primary.btn-sm {
  background-color: #fff !important;
}
.btn-blg.btn.btn-outline-primary.btn-sm,
.btn-start.btn.btn-primary.btn-sm {
  font-size: 12px !important;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #contactUsForm h4 {
    font-size: 21px;
  }

  .btn-blg.btn.btn-outline-primary.btn-sm,
  .btn-start.btn.btn-primary.btn-sm {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  #contactUsForm h4 {
    font-size: 17px;
  }

  .btn-blg.btn.btn-outline-primary.btn-sm,
  .btn-start.btn.btn-primary.btn-sm {
    font-size: clamp(9px, 1vw, 18px) !important;
  }
}

@media screen and (max-width: 575px) {
  #contactUsForm .form-check-label {
    font-size: 10.5px;
  }

  #contactUsForm .btn-submit {
    font-size: calc(8px + 1vw) !important;
    border-radius: 20px;
  }
 .btn-success {
    margin-left: 2.5rem !important;
  }

  #contactUsForm h4 {
    font-size: 16px;
  }

  .btn-blg.btn.btn-outline-primary.btn-sm,
  .btn-start.btn.btn-primary.btn-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width:325px) {
  
.borrowerLeadGeneration .btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto 12px;
  grid-gap: 1vw;
  gap: 1vw;
  border-radius: 0.5em;
  text-align: center;
  font-weight: 700;
  /* left: 63rem; */
  position: relative;
  left: -1rem;
}
}
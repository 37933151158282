.side-drawer {
    height: 100%;
    background: #f5911e;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    /* display: none; */
}

.side-drawer.open {
    display: block;
    transform: translateX(0);
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transition: transform .4s ease-out;
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a:hover, 
.side-drawer a:active {
    color: #fff;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap');
/* html {
  scroll-behavior: smooth;
} */
body {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.4;
  color: #484d54;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px 0px 10px 0px;
  color: #3c4d6b;
  font-weight: 500;
}
h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0;
  font-family: inherit;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: 120% !important;
  color: #164686;
  margin: 0;
}

.modalLink:hover,
.modalLink:focus {
  color: #0056b3;
}

.Modal-colors {
  background: #f5911e;
  color: #fff;
}

h1 {
  font-size: 32px !important;
  line-height: 44px;
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

#aboutWapper h2 {
  font-size: 24px !important;
  font-weight: 400 !important;
}
#aboutWrapper p {
  color: #484d54 !important;
}
#faqWrapper h1 {
  font-size: 32px;
  font-weight: 400;
  color: #164686;
}
#home h2 {
  font-size: 18px !important;
  font-weight: 100px;
  color: #d1d1d1;
}
#btnsSection .portfolio-details h2 {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin: 0 !important;
}
#home h4 {
  margin: 0;
  padding: 0 0 10px 0;
}
.pinside40 h2 {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #164686 !important;
  padding: 0 0 10px 0 !important;
}
.pinside40 h3 {
  font-size: 20px;
  font-weight: 400 !important;
  color: #164686 !important;
  padding: 0 0 10px 0 !important;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
  line-height: 1.6;
}

p {
  margin: 0 0 26px;
  line-height: 1.6;
  font-weight: 400;
}

ul li,
ol li {
  line-height: 1.8
}

a {
  color: #F5911E;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#calculatorsWrapper a:focus,
#calculatorsWrapper a:hover,
.lender-apply a:focus,
.lender-apply a:hover,
.lender-details a:focus,
.lender-details a:hover,
.pinside40 a:hover {
  text-decoration: none;
  color: #15549a;
  opacity: .8;
}

#calculatorsWrapper a,
.lender-apply a,
.lender-details a,
.pinside40 a {
  color: #F5911E;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#calculatorsWrapper h3.sun {
  font-size: 20px;
  padding: 0 0 10px 0;
  margin: 0;
}

#calculatorsWrapper h4.sun {
  font-size: 16px;
  padding: 0 0 10px 0;
  margin: 0;
}

.lender-apply a.btns,
.lender-details a.btn.btn-sm.rounded.box-shadow.btn-primary,
.pinside40 a.btns {
  color: #fff!important;
  background-color: #F5911E;
  padding: 20px 30px;
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
}


.lender-apply a.btns:hover,
.lender-details a.btn.btn-sm.rounded.box-shadow.btn-primary:hover,
.pinside40 a.btns:hover {
  color: #F5911E!important;
  background-color: #fff;
  border: 1px solid #F5911E;
}
strong {
  font-weight: 600;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 0px;
  font-size: 21px;
  border-left: 5px solid #eff1f4;
  color: #414346;
  font-style: italic;
  line-height: 35px;
  font-family: 'Merriweather', serif;
  font-weight: 700;
}

blockquote.alignright {
  max-width: 50%;
}

blockquote.alignleft {
  max-width: 50%;
}

p:last-child {
  margin-bottom: 0px;
}

::selection {
  background: #ffecb3;
  color: #333;
}
.bg-sun {
  background: #F5911E!important;
}
.sun {
  color: #F5911E!important;
}
.text-highlight {
  color: #ffecb3;
}

.highlight {
  color: #F5911E;
}
.listnone {
  list-style: none;
  margin: 0px 0px 20px 0px;
  padding: 0;
}

.required {
  color: red
}

.lead {
  color: #3c4d6b;
  line-height: 36px;
  font-size: 24px;
}
.text-white {
  color: #fff;
}

.title-white {
  color: #fff;
}

.title {
  color: #3c4d6b;
}

.borderTop {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.borderBottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid;
  padding: .375rem .75rem;
  margin: 5px;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
  font-size: 14px !important;
  border-radius: 8px;
  line-height: 18px !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px 30px;
  font-weight: 600;
}

.btn-default {
  background-color: #F5911E;
  border: 1px solid #F5911E;
  color: #fff;
}

.btn-primary {
  background-color: #F5911E;
  border: 1px solid #F5911E;
  border-radius: 8px;
  color: #fff;
}
.btn-primary:hover {
  color: #F5911E;
  background-color: #fff;
  border: 1px solid #F5911E;
}

a, .btn {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all .15s ease-in-out;
  -moz-transition: all .15s ease-in-out;
  -ms-transition: all .15s ease-in-out;
  -o-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
}

.btn-default:hover {
  color: #F5911E;
  background-color: #fff;
}

img {
  width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}

.img-circle {
  border-radius: 50%;
}

.img-whp {
  width: 100% !important;
}
.box-shadow {
  box-shadow: 1px 1px 8px #c7c7c7;
}

.wrapper-content {
  margin-top: -40px;
  margin-bottom: 100px;
  position: relative;
}

.pinside40 {
  padding: 40px;
}

.scrollToTop {
  background-color: rgba(245, 145, 30)!important;
  transition-duration: .5s;
  border-radius: 8px;
  padding: .25rem;
  opacity: .55!important;
}

.scrollToTop:hover {
  opacity: 1!important;
  box-shadow: 2px 2px 6px #d2d2d2
}


/*-----------------------------------
Space css class
-----------------------------------*/

/*----------------
margin space
-----------------*/

.mb0 {
  margin-bottom: 0px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb100 {
  margin-bottom: 100px;
}

.mt0 {
  margin-top: 0px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt60 {
  margin-top: 60px;
}

.mt80 {
  margin-top: 80px;
}

.mt100 {
  margin-top: 100px;
}

/*----------------
less margin space from top
-----------------*/
.mt-20 {
  margin-top: -20px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-80 {
  margin-top: -80px;
}

/*---------------
Padding space
-----------------*/
.nopadding {
  padding: 0px;
}

.nopr {
  padding-right: 0px;
}

.nopl {
  padding-left: 0px;
}

.pinside10 {
  padding: 10px;
}

.pinside20 {
  padding: 20px;
}

.pinside30 {
  padding: 30px;
}

.pinside40 {
  padding: 40px;
}

.pinside50 {
  padding: 50px;
}

.pinside60 {
  padding: 60px;
}

.pinside80 {
  padding: 80px;
}

.pdt10 {
  padding-top: 10px;
}

.pdt20 {
  padding-top: 20px;
}

.pdt30 {
  padding-top: 30px;
}

.pdt40 {
  padding-top: 40px;
}

.pdt60 {
  padding-top: 60px;
}

.pdt80 {
  padding-top: 80px;
}

.pdb10 {
  padding-bottom: 10px;
}

.pdb20 {
  padding-bottom: 20px;
}

.pdb30 {
  padding-bottom: 30px;
}

.pdb40 {
  padding-bottom: 40px;
}

.pdb60 {
  padding-bottom: 60px;
}

.pdb80 {
  padding-bottom: 80px;
}

.pdl10 {
  padding-left: 10px;
}

.pdl20 {
  padding-left: 20px;
}

.pdl30 {
  padding-left: 30px;
}

.pdl40 {
  padding-left: 40px;
}

.pdl60 {
  padding-left: 60px;
}

.pdl80 {
  padding-left: 80px;
}

.pdr10 {
  padding-right: 10px;
}

.pdr20 {
  padding-right: 20px;
}

.pdr30 {
  padding-right: 30px;
}

.pdr40 {
  padding-right: 40px;
}

.pdr60 {
  padding-right: 60px;
}

.pdr80 {
  padding-right: 80px;
}
.y {
  padding-top: 220px;
}
/*---------------
Section Space
* Section space help to create top bottom space.
*
-----------------*/
.section-space20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-space40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-space60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-space80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-space100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-space120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-space140 {
  padding-top: 140px;
  padding-bottom: 140px;
}
/*--------------------------------
Product Single Sidebar
---------------------------------*/

.product-tabs ul {
    position: relative;
    z-index: 0;
}

.product-tabs .nav > li {
    margin-top: -1px;
}

.product-tabs .nav > li.active a {
    border-right: 1px solid #f4f6f8;
    background: #eff3f8;
    color: #F5911E;
}

.product-tabs .nav > li > a {
    background: #fff;
    border-bottom: 1px solid #dee7f2;
    border-right: 1px solid #dee7f2;
    border-left: 1px solid #dee7f2;
    border-top: 1px solid #dee7f2;
    color: #7e93ae;
}

.product-tabs .nav > li > a:hover {
    background: #eff3f8;
    border-bottom: 1px solid #dee7f2;
    border-right: 1px solid transparent;
    border-left: 1px solid #dee7f2;
    border-top: 1px solid #dee7f2;
    color: #F5911E;
}

.product-tabs .nav > li > a i {
    padding-right: 10px;
}

.tab-content {
    background: #f8f9fa;
    padding: 40px;
    border: 1px solid #dee7f2;
    position: relative;
    z-index: 8;
    margin-left: -1px;
    margin-top: -1px;
}

/*-----------------------------------------
Bullet
------------------------------------------*/
.bullet {
  position: relative;
  padding-left: 25px;
  line-height: 32px;
}
.bullet-check-circle-default {
  display: block;
  position: absolute;
  left: 0;
  font-size: 13px;
  color: #10a874;
  margin-top: .5rem!important;
}

.rightIcon {
  font-size: 1.25rem;
  margin-right: .5rem;
  color: #747474;

}

/*--------------------------------
Lender Listing Page
---------------------------------*/
.lender-listing {
  border: 1px solid #dee7f2;
  margin-bottom: 30px;
}

.lender-head {
  padding: 15px;
  background: #eff3f8;
  border-bottom: 1px solid #dee7f2;
}

.lender-logo {
  display: inline-block;
  width: 170px;
}

.lender-reviews {
  color: #ffb607;
  display: inline-block;
  padding-top: 15px;
}

.lender-rate-box {
  padding: 15px;
  border-bottom: 1px solid #dee7f2;
}

.lender-ads-rate {
  border-right: 1px solid #dee7f2;
}

.lender-ads-rate,
.lender-compare-rate {
  display: inline-block;
  text-align: center;
  width: 143px;
}

.lender-rate-value {
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
}

.lender-feature-list {
  padding: 20px;
  border-bottom: 1px solid #dee7f2;
}

.lender-feature-list ul {
  margin-bottom: 0px;
}

.lender-feature-list ul li {
  font-size: 14px;
  margin-bottom: 8px;
}

.lender-actions {
  text-align: center;
  padding: 20px;
}

.lender-actions .btn-link {
  margin-top: 30px;
  font-size: 14px;
}

.lender-head-sections {
  margin-bottom: 60px;
}

.lender-rates-list {
  background-color: #eff4f9;
  padding: 20px;
  border-radius: 4px;
}

.lender-loan-sections,
.lender-apply,
.lender-features,
.lender-rates-list {
  margin-bottom: 60px;
}

/*-----------------------------------------
icon
------------------------------------------*/

.icon-1x {
    font-size: 24px;
}

/* default icon size of font*/
.icon-2x {
    font-size: 48px;
}

.icon-4x {
    font-size: 68px;
}

.icon-6x {
    font-size: 88px;
}

.icon-8x {
    font-size: 108px;
}

.icon-default {
    color: #F5911E;
}

.icon-primary {
    color: #15549a;
}

.icon-secondary {
    color: #bcbcbc;
}

.dark-line {
    border-top: 2px solid #2b2e35;
}

.fa-default {
    color: #F5911E;
}

.fa-success {
    color: #6eb232;
}
.table-head {
  background: #eff3f8;
}

.loan-title {
  margin-bottom: 30px;
}

.table-bordered {
  border: 1px solid transparent;
  color: #333;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #dee4ee;
  color: #3c4d6b;
}
.table-1 thead tr th {
  color: #fff;
}
.table-1 table, .table-1 thead {
  border-radius: 12px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fafcfd;
}

.table > thead > tr > th {
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: center;
}

.tablebg {
  background-color: #ddd!important;
}

.teamWrapper .carousel-control-prev, .carousel-control-next,.carousel-indicators {
  display: none!important;
}

.faqAccordion .card {
  border: none;
  padding: 10px 0px 10px 30px;
}
.faqAccordion .card .card-header {
  padding: .75rem .0rem;
  background: #fff;
}
.faqAccordion .card .card-header .btn {
  white-space:normal;
  text-align: left;
}

.page {
  position: absolute;
  top: 100px;
  left:0;
  right: 0;
}

.fade-appear, .fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active, .fade-enter .fade-enter-active {
  opacity: 1;
  transition: opacity 150ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit .fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.pricing-table a {
  transition: .5s;
}

.pricing-table h3, .pricing-table a {
  color: #164686;
}

.pricing-table p {
  padding-left: 10px;;
}
.compare-action {
  padding-top: 15px;
}
.container {
  max-width: 1140px !important;
}

.outline {
  border: 1px solid #dee4ee;
}

.rate, .fees, .compare-rate, .repayment {
  font-size: 18px;
  color: #4b5565;
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: 600;
}

#sitemapWrapper p {
    margin: 0 0 10px;
}

.tcpa {
  margin-bottom: 10px;
}
.tcpa p {
  color: #fff;
  font-size: 12px;
  line-height: 1.5!important;
  text-align: justify!important;
}

.rates-table td {
  vertical-align: middle;
}

#disclaimersWrapper td a {
  white-space: nowrap;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #f5911e63;
}


@media (max-width: 1199.99px) {
  #faqWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/faq.jpg) left /cover no-repeat;
  }
}

@media screen and (max-width: 768.99px) {
  .teamWrapper {
    margin-top: 75px;
  }
}

@media (max-width: 781.99px) {
  
  #aboutWapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/about-md.jpg) center/cover no-repeat;
  }
  #fhaWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/fha-header-md.jpg) center/cover no-repeat;
  }
  #conventionalWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/conventional-md.jpg) center/cover no-repeat;
  }
  #renoWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/reno-md.jpeg) center/cover no-repeat;
  }
  #reveWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/reverse-md.jpg) center/cover no-repeat;
  }
  #vaWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/va-md.jpeg) center/cover no-repeat;
  }
  #buyingWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/buy-md.jpg) left /cover no-repeat;
  }
  #refiWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/refinancing-md.jpg) left /cover no-repeat;
  }
  #calculatorsWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/calculator-header-md.jpg) center /cover no-repeat;
  }
  #documentsWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/documentsneeded-md.jpg) center /cover no-repeat;
  }
  #faqWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/faq-md.jpg) center /cover no-repeat;
  }
  #termsWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/kitchen-md.jpg) center /cover no-repeat;
  }
  #sitemapWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/sitemap-md.jpg) center /cover no-repeat;
  }
}
@media (max-width: 500px) {
  .globalApplyModalWrapper .form-control {
    font-size: 14px;
  }
}

.globalApplyModal {
  z-index: 9999 !important;
}

.globalApplyModal > div > div > div > div {
  font-size: 21px !important;
}

.globalApplyModal > div > div {
  padding: 10px 5px;
  overflow-x: auto;
  background-repeat: no-repeat;
  border-radius: 15px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}

.globalApplyModal > div > div > div {
  border-bottom: none !important;
}

.globalApplyModal label {
  color: #333;
}

.globalApplyModal .invalid-feedback {
  color: #dc3545;
}

.globalApplyModal .modal-header {
  padding: 0 20px;
}

.apply_btn_color {
  color: white !important;
}

.btn {
  cursor: pointer;
}

.apply_btn_color:hover {
  background-color: #fff;
  border: 1px solid #f5911e;
  color: #f5911e !important;
  transition-duration: 0.6s;
}

.blg-col-0 {
  display: none;
}

.modal-content {
  border-radius: 15px !important;
  margin-top: 5rem;
}

.modal-header {
  border: none !important;
  margin-top: -10px;
  padding-bottom: 0 !important;
}

.modal-content .formHeader {
  font-size: 22px;
  text-align: center;
  color: #00aae4;
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
}

.modal-content .close {
  color: #f5911e;
}

.modal-content input:not(.form-check-input),
.modal-content select {
  border-left: 2px solid #1d90cc;
  border-right: 2px solid #1d90cc;
}

.modal-content .blg-col-6 {
  display: none;
}

.modal-content h6 {
  color: #484d54;
  font-size: 11px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.modal-content .consent-font {
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}

.modal-content .consent-font a {
  color: #f5911e;
}

.modal-content .consent-font a:hover {
  color: #0056b3;
}

.modal-content .form-check {
  font-size: 12px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.modal-content .btn-submit {
  background-color: #f5911e;
  border-radius: 25px !important;
  padding: 7px 15px !important;
  font-size: 10px;
  border: 1px solid #f5911e;
}
.modal-content .btn-submit:hover,
.modal-content .btn-submit:focus {
  background-color: #fff !important;
  color: #f5911e;
  border-color: #f5911e;
  outline: rgba(245, 145, 30, 0.8) !important;
}
.btn-submit{
  background-color: #f5911e !important;
  border-radius: 20px !important;
  padding: 7px 15px !important;
  border: 1px solid #f5911e !important;
}
.btn-submit:hover,
.btn-submit:focus {
  background-color: #fff !important;
  color: #f5911e !important;
  border-color: #f5911e !important;
  outline: rgba(245, 145, 30, 0.8) !important;
}
.modal-content .btn-success {
  background-color: #198754 !important;
  color: #fff !important;
  border: 1px solid #198754 !important;
}
.modal-content .btn-success:hover,
.modal-content .btn-success:focus {
  background-color: #149257 !important;
  border: 2px solid #149257 !important;
  color: #fff !important;
}

@media (max-width: 581.99px) {
  .wrapper-content {
    margin-top: -8px;
  }
}

@media (max-width: 481.99px) {
  #vaWrapper header {
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url(./images/va-sm.jpeg) center/cover no-repeat;
  }

  .tab-content {
    padding: 20px;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  .teamWrapper {
    margin-top: 75px;
  }
}


.borrowerLeadGeneration img.blg-img {
  margin: 12px 12px !important;
}

.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    height: 56px;
    z-index: 100;
    padding: 1rem;
}


.toolbar_nav {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-around;
}

.toolbar_logo {
    margin-left: 1rem;
}

.toolbar_logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.spacer {
    flex: 1;
}

.toolbar_nav-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_nav-items li {
    padding:  0 .5rem;
}

.toolbar_nav-items a {
    color: white;
    text-decoration: none;

}

.toolbar_nav-items a:hover, 
.toolbar_nav-items a:active {
    color: #fa923f;
}

@media (max-width: 991px) {
    .toolbar_nav {
        justify-content: space-between;
    }
    .toolbar_nav-items{
        display: none;
    }
    #mainNav {
        display: none;
    }
    .toolbar {
        background: #F5911E;
        height: 75px;
    }
    .nav-link, MyHashLink {
        color: #fff !important;
    }
    .dropdown-menu {
       border-radius: none!important;
        border: none;
        background: #F5911E;
        box-shadow: none;
    }
    .dropdown-item {
        color: #fff !important;
    }
    
    .dropdown-item:hover {
        color: #fa933fd7 !important;
        background-color: transparent;
    }
    nav .side-drawer .open {
        display: flex;
        align-items: center;
    }
    .navbar-brand img {
        width: 300px;
    }
    .mobileLogo {
        display: block;
    }
}
@media  screen and (min-width: 320px) and (max-width:400px){
    .navbar-brand img {
        width: 230px!important;
    }
}
@media  screen and (min-width: 350px) and (max-width:400px){
    .navbar-brand img {
        width: 250px!important;
    }
}
 
@media (max-width: 767.67px) {
    .toolbar {
        padding: 1rem .25rem;
    }
}

@media (max-width: 499.91px) {
    .toolbar {
        padding: 1rem .5rem;
    }

    .toolbar_nav {
        padding: 0 .25rem;
    }
}

@media (min-width: 992px){
    .toolbar_toggle-button {
        display: none;
    }

    .toolbar_logo {
        margin-left: 0;
    }
    .mobileLogo {
        display: none;
    }
}